import { ActionType, createAction, props } from "@ngrx/store";
import {
  Categories,
  StatementTransaction,
  TransactionFlagsForGrid,
  UpdateTransactionCategory,
} from "src/app/models/TransactionReviewModels/statement-transaction.model";
import { IncludeExcludeLoanTransaction } from "../transaction/transaction.types";

export const GET_ALL_STATEMENT_TRANSACTIONS_BY_LOANID = "GET_ALL_STATEMENT_TRANSACTIONS_BY_LOANID";
export const GET_ALL_STATEMENT_TRANSACTIONS_BY_LOANID_SUCCESS = "GET_ALL_STATEMENT_TRANSACTIONS_BY_LOANID_SUCCESS";
export const GET_ALL_STATEMENT_TRANSACTIONS_BY_LOANID_FAILURE = "GET_ALL_STATEMENT_TRANSACTIONS_BY_LOANID_FAILURE";

export const RESET_TRANSACTION_DETAILS = "RESET_TRANSACTION_DETAILS";

export const ADD_OR_UPDATE_TRANSACTION_FLAGS = "ADD_OR_UPDATE_TRANSACTION_FLAGS";
export const ADD_OR_UPDATE_TRANSACTION_FLAGS_SUCCESS = "ADD_OR_UPDATE_TRANSACTION_FLAGS_SUCCESS";
export const ADD_OR_UPDATE_TRANSACTION_FLAGS_FAILURE = "ADD_OR_UPDATE_TRANSACTION_FLAGS_FAILURE";

export const UPDATE_TRANSACTION_CATEGORY = "UPDATE_TRANSACTION_CATEGORY";
export const UPDATE_TRANSACTION_CATEGORY_SUCCESS = "UPDATE_TRANSACTION_CATEGORY_SUCCESS";
export const UPDATE_TRANSACTION_CATEGORY_FAILURE = "UPDATE_TRANSACTION_CATEGORY_FAILURE";

export const GET_TRANSACTION_FLAG = "GET_TRANSACTION_FLAG";
export const GET_TRANSACTION_FLAG_SUCCESS = "GET_TRANSACTION_FLAG_SUCCESS";
export const GET_TRANSACTION_FLAG_FAILURE = "GET_TRANSACTION_FLAG_FAILURE";

export const GET_TRANSACTION_CATEGORY = "GET_TRANSACTION_CATEGORY";
export const GET_TRANSACTION_CATEGORY_SUCCESS = "GET_TRANSACTION_CATEGORY_SUCCESS";
export const GET_TRANSACTION_CATEGORY_FAILURE = "GET_TRANSACTION_CATEGORY_FAILURE";

export const INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION = "INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION";
export const INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION_SUCCESS = "INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION_SUCCESS";
export const INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION_FAILURE = "INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION_FAILURE";

export const RESET_INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION = "RESET_INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION";
export const RESET_INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION_SUCCESS =
  "RESET_INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION_SUCCESS";
export const RESET_INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION_FAILURE =
  "RESET_INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION_FAILURE";

export const GetAllStatementTransactionsByLoanId = createAction(
  GET_ALL_STATEMENT_TRANSACTIONS_BY_LOANID,
  props<{ loanId: string }>()
);
export const GetAllStatementTransactionsByLoanIdSuccess = createAction(
  GET_ALL_STATEMENT_TRANSACTIONS_BY_LOANID_SUCCESS,
  props<{ data: StatementTransaction[] }>()
);
export const GetAllStatementTransactionsByLoanIdFailure = createAction(
  GET_ALL_STATEMENT_TRANSACTIONS_BY_LOANID_FAILURE,
  props<{ errors?: string[]; message: string }>()
);
export const ResetTransactionDetails = createAction(RESET_TRANSACTION_DETAILS);

export const AddOrUpdateTransactionFlags = createAction(
  ADD_OR_UPDATE_TRANSACTION_FLAGS,
  props<{ statementTransactionId: string; flagsCode: string; loanId: string }>()
);
export const AddOrUpdateTransactionFlagsSuccess = createAction(
  ADD_OR_UPDATE_TRANSACTION_FLAGS_SUCCESS,
  props<{ data: StatementTransaction[] }>()
);
export const AddOrUpdateTransactionFlagsFailure = createAction(
  ADD_OR_UPDATE_TRANSACTION_FLAGS_FAILURE,
  props<{ errors?: string[]; message: string }>()
);

export const UpdateTransactionCategoryData = createAction(
  UPDATE_TRANSACTION_CATEGORY,
  props<{ updatePayload: UpdateTransactionCategory }>()
);
export const UpdateTransactionCategoryDataSuccess = createAction(
  UPDATE_TRANSACTION_CATEGORY_SUCCESS,
  props<{ data: StatementTransaction[] }>()
);
export const UpdateTransactionCategoryDataFailure = createAction(
  UPDATE_TRANSACTION_CATEGORY_FAILURE,
  props<{ errors?: string[]; message: string }>()
);

export const GetTransactionFlagsData = createAction(GET_TRANSACTION_FLAG);
export const GetTransactionFlagsSuccess = createAction(
  GET_TRANSACTION_FLAG_SUCCESS,
  props<{ data: TransactionFlagsForGrid[] }>()
);
export const GetTransactionFlagsFailure = createAction(
  GET_TRANSACTION_FLAG_FAILURE,
  props<{ errors?: string[]; message: string }>()
);

export const GetTransactionCategoryData = createAction(GET_TRANSACTION_CATEGORY);
export const GetTransactionCategorySuccess = createAction(
  GET_TRANSACTION_CATEGORY_SUCCESS,
  props<{ data: Categories[] }>()
);
export const GetTransactionCategoryFailure = createAction(
  GET_TRANSACTION_CATEGORY_FAILURE,
  props<{ errors?: string[]; message: string }>()
);

export const IncludeExcludeForIncomeCalculation = createAction(
  INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION,
  props<{ payload: IncludeExcludeLoanTransaction }>()
);
export const IncludeExcludeForIncomeCalculationSuccess = createAction(
  INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION_SUCCESS,
  props<{ data: StatementTransaction[] }>()
);
export const IncludeExcludeForIncomeCalculationFailure = createAction(
  INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION_FAILURE,
  props<{ errors?: string[]; message: string }>()
);

export const ResetIncludeExcludeForIncomeCalculation = createAction(
  RESET_INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION,
  props<{ payload: IncludeExcludeLoanTransaction }>()
);
export const ResetIncludeExcludeForIncomeCalculationSuccess = createAction(
  RESET_INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION_SUCCESS,
  props<{ data: StatementTransaction[] }>()
);
export const ResetIncludeExcludeForIncomeCalculationFailure = createAction(
  RESET_INCLUDE_EXCLUDE_FOR_INCOME_CALCULATION_FAILURE,
  props<{ errors?: string[]; message: string }>()
);

export type TransactionReviewActions =
  | ActionType<typeof GetAllStatementTransactionsByLoanId>
  | ActionType<typeof GetAllStatementTransactionsByLoanIdSuccess>
  | ActionType<typeof GetAllStatementTransactionsByLoanIdFailure>
  | ActionType<typeof ResetTransactionDetails>
  | ActionType<typeof AddOrUpdateTransactionFlags>
  | ActionType<typeof AddOrUpdateTransactionFlagsSuccess>
  | ActionType<typeof AddOrUpdateTransactionFlagsFailure>
  | ActionType<typeof UpdateTransactionCategoryData>
  | ActionType<typeof UpdateTransactionCategoryDataSuccess>
  | ActionType<typeof UpdateTransactionCategoryDataFailure>
  | ActionType<typeof GetTransactionFlagsData>
  | ActionType<typeof GetTransactionFlagsSuccess>
  | ActionType<typeof GetTransactionFlagsFailure>
  | ActionType<typeof GetTransactionCategoryData>
  | ActionType<typeof GetTransactionCategorySuccess>
  | ActionType<typeof GetTransactionCategoryFailure>
  | ActionType<typeof IncludeExcludeForIncomeCalculation>
  | ActionType<typeof IncludeExcludeForIncomeCalculationSuccess>
  | ActionType<typeof IncludeExcludeForIncomeCalculationFailure>
  | ActionType<typeof ResetIncludeExcludeForIncomeCalculation>
  | ActionType<typeof ResetIncludeExcludeForIncomeCalculationSuccess>
  | ActionType<typeof ResetIncludeExcludeForIncomeCalculationFailure>;
