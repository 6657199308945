 
import { ActionType, createAction, props } from "@ngrx/store";
import {
  IDocumentFieldVersionListLookUp,
  ILOSField,
  IOption,
  IRule,
  IRuleFilters,
  IRuleWithId,
} from "./rule-engine.types";

export const ADD_RULE = "ADD_RULE";
export const UPDATE_RULE = "UPDATE_RULE";
export const GET_RULE_BY_ID = "GET_RULE_BY_ID";
export const GET_ADDED_RULE_SUCCESS = "GET_ADDED_RULE_SUCCESS";
export const GET_ADDED_RULE_FAILURE = "GET_ADDED_RULE_FAILURE";
export const GET_UPDATED_RULE_SUCCESS = "GET_UPDATED_RULE_SUCCESS";
export const GET_RULE_BY_ID_SUCCESS = "GET_RULE_BY_ID_SUCCESS";
export const CLEAR_RULE_STATE = "CLEAR_RULE_STATE";
export const UPDATE_DOCUMENT_TYPES = "UPDATE_DOCUMENT_TYPES";
export const UPDATE_DOCUMENT_FIELD_VERSION_LIST_LOOKUP = "UPDATE_DOCUMENT_FIELD_VERSION_LIST_LOOKUP";
export const GET_LOS_FIELD_LIST_SUCCESS = "GET_LOS_FIELD_LIST_SUCCESS";
export const GET_LOS_FIELD_LIST = "GET_LOS_FIELD_LIST";
export const UPDATE_RULE_FILTERS = "UPDATE_RULE_FILTERS";

export const AddRule = createAction(ADD_RULE, props<{ rule: IRule }>());

export const GetAddedRuleSuccess = createAction(GET_ADDED_RULE_SUCCESS, props<{ rule: IRuleWithId }>());
export const GetAddedRuleFailure = createAction(GET_ADDED_RULE_FAILURE, props<{ rule: undefined, errorMessage: string }>());

export const UpdateRule = createAction(UPDATE_RULE, props<{ ruleId: string; rule: IRule }>());

export const GetUpdatedRuleSuccess = createAction(GET_UPDATED_RULE_SUCCESS, props<{ rule: IRuleWithId }>());

export const GetRuleById = createAction(GET_RULE_BY_ID, props<{ ruleId: string , projectId: number }>());

export const GetRuleByIdSuccess = createAction(GET_RULE_BY_ID_SUCCESS, props<{ rules: IRuleWithId[] }>());

export const ClearRuleState = createAction(CLEAR_RULE_STATE);

export const UpdateDocumentTypes = createAction(UPDATE_DOCUMENT_TYPES, props<{ documentTypes: IOption<string>[] }>());

export const UpdateDocumentFieldVersionListLookUp = createAction(
  UPDATE_DOCUMENT_FIELD_VERSION_LIST_LOOKUP,
  props<{ documentFieldVersionListLookUp: IDocumentFieldVersionListLookUp }>()
);

export const getLOSFieldList = createAction(GET_LOS_FIELD_LIST);

export const getLOSFieldListSuccess = createAction(GET_LOS_FIELD_LIST_SUCCESS, props<{ LOSFieldList: ILOSField[] }>());

export const updateRuleFilters = createAction(UPDATE_RULE_FILTERS, props<{ ruleFilters: IRuleFilters | undefined }>());

export type RuleEngineActions =
  | ActionType<typeof AddRule>
  | ActionType<typeof UpdateRule>
  | ActionType<typeof GetRuleById>
  | ActionType<typeof GetAddedRuleSuccess>
  | ActionType<typeof GetUpdatedRuleSuccess>
  | ActionType<typeof GetRuleByIdSuccess>
  | ActionType<typeof ClearRuleState>
  | ActionType<typeof UpdateDocumentTypes>
  | ActionType<typeof UpdateDocumentFieldVersionListLookUp>
  | ActionType<typeof getLOSFieldList>
  | ActionType<typeof getLOSFieldListSuccess>
  | ActionType<typeof updateRuleFilters>
  | ActionType<typeof GetAddedRuleFailure>;
