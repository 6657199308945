/* eslint-disable */
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiResponseModel } from "src/app/models/common.model";
import { ICondition, IDocumentList, UpdateCoreLoan } from "src/app/store/data-extraction/data-extraction.types";
import { TokenStorageService } from "../TokenStorageService/token-storage.service";

@Injectable({
  providedIn: "root",
})
export class DataExtractionService {
  // private baseUrl = "https://rulesenginevalidator-dev.azurewebsites.net/";
  idToken: string;

  constructor(
    private http: HttpClient,
    tokenStorageService: TokenStorageService,
    @Inject("RULE_VALIDATOR_API_URL") private baseUrl: string,
  ) {
    this.idToken = tokenStorageService.getAccessToken() || "";
  }

  getDocumentTypeList(loanId: string,projectId:number): Observable<ApiResponseModel<IDocumentList[]>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.get<ApiResponseModel<IDocumentList[]>>(`${this.baseUrl}api/Documents/${projectId}/GetDocByloanId/${loanId}`, { headers });
  }
  getConditionsForLoanId(loanId: string,projectId:number): Observable<ApiResponseModel<ICondition[]>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });

    return this.http.get<ApiResponseModel<ICondition[]>>(`${this.baseUrl}api/Conditions/${projectId}/${loanId}`, { headers });
  }



  updateCoreLoan(payload: UpdateCoreLoan, loanId: string, ruleId: string,projectId:number) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    
    return this.http.put<ApiResponseModel<any>>(
      this.baseUrl + "api/CoreLoanRules/"+projectId+"/" + loanId + "/" + ruleId,
      payload ,
      { headers }
    );
  }

  fetchFileById(loanId: string, fileId: string,projectId:number) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });

    return this.http.get<ApiResponseModel<string>>(`${this.baseUrl}api/Documents/${projectId}/${loanId}/${fileId}`, {
      headers,
    });
  }

  updateCompletion(loanId: string,projectId:number) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
  
    const url = `${this.baseUrl}api/Completion/${projectId}/${loanId}`;
  
    return this.http.put<ApiResponseModel<any>>(
      url,
      { headers }
    );
  }
}
