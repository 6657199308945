import { ActionType, createAction, props } from "@ngrx/store";
import { CalculateIncomeBody, EmployeeCount, LoanHeaderDetails, TypeOfBusiness } from "src/app/models/loans";
import { AccountSummaryDto, IncomeDetailsDto, TransactionCategoriesDto } from "./incomeCalculation.types";

export const RECALCULATE_THRESHOLD = "RECALCULATE_THRESHOLD";
export const RECALCULATE_THRESHOLD_SUCCESS = "RECALCULATE_THRESHOLD_SUCCESS";
export const RECALCULATE_THRESHOLD_FAILURE = "RECALCULATE_THRESHOLD_FAILURE";

export const GET_EXPORT_LOANS = "GET_EXPORT_LOANS";
export const GET_EXPORT_LOANS_SUCCESS = "GET_EXPORT_LOANS_SUCCESS";
export const GET_EXPORT_LOANS_FAILURE = "GET_EXPORT_LOANS_FAILURE";

export const GET_ACCOUNT_DETAILS = "GET_ACCOUNT_DETAILS";
export const GET_ACCOUNT_DETAILS_SUCCESS = "GET_ACCOUNT_DETAILS_SUCCESS";
export const GET_ACCOUNT_DETAILS_FAILURE = "GET_ACCOUNT_DETAILS_FAILURE";

export const GET_INCOME_DETAILS = "GET_INCOME_DETAILS";
export const GET_INCOME_DETAILS_SUCCESS = "GET_INCOME_DETAILS_SUCCESS";
export const GET_INCOME_DETAILS_FAILURE = "GET_INCOME_DETAILS_FAILURE";

export const GET_LOAN_HEADER_DETAILS_BY_ID = "GET_LOAN_HEADER_DETAILS_BY_ID";
export const GET_LOAN_HEADER_DETAILS_BY_ID_SUCCESS = "GET_LOAN_HEADER_DETAILS_BY_ID_SUCCESS";
export const GET_LOAN_HEADER_DETAILS_BY_ID_FAILURE = "GET_LOAN_HEADER_DETAILS_BY_ID_FAILURE";

export const GET_TYPE_OF_BUSINESS = "GET_TYPE_OF_BUSINESS";
export const GET_TYPE_OF_BUSINESS_SUCCESS = "GET_TYPE_OF_BUSINESS_SUCCESS";
export const GET_TYPE_OF_BUSINESS_FAILURE = "GET_TYPE_OF_BUSINESS_FAILURE";

export const GET_EMPLOYEE_COUNT = "GET_EMPLOYEE_COUNT";
export const GET_EMPLOYEE_COUNT_SUCCESS = "GET_EMPLOYEE_COUNT_SUCCESS";
export const GET_EMPLOYEE_COUNT_FAILURE = "GET_EMPLOYEE_COUNT_FAILURE";

export const CALCULATE_INCOME = "CALCULATE_INCOME";
export const CALCULATE_INCOME_SUCCESS = "CALCULATE_INCOME_SUCCESS";
export const CALCULATE_INCOME_FAILURE = "CALCULATE_INCOME_FAILURE";

export const GET_CATEGORY_BY_LOANID = "GET_CATEGORY_BY_LOANID";
export const GET_CATEGORY_BY_LOANID_SUCCESS = "GET_CATEGORY_BY_LOANID_SUCCESS";
export const GET_CATEGORY_BY_LOANID_FAILURE = "GET_CATEGORY_BY_LOANID_FAILURE";

export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const UPDATE_ACCOUNT_FAILURE = "UPDATE_ACCOUNT_FAILURE";

export const UPDATE_ACCOUNT_STATUS = "UPDATE_ACCOUNT_STATUS";
export const UPDATE_ACCOUNT_STATUS_SUCCESS = "UPDATE_ACCOUNT_STATUS_SUCCESS";
export const UPDATE_ACCOUNT_STATUS_FAILURE = "UPDATE_ACCOUNT_STATUS_FAILURE";

export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE";

export const RecalculateThreshold = createAction(
  RECALCULATE_THRESHOLD,
  props<{ loanId: string; thresholdFactor: number }>()
);
export const RecalculateThresholdSuccess = createAction(RECALCULATE_THRESHOLD_SUCCESS, props<{ data: string }>());
export const RecalculateThresholdFailure = createAction(
  RECALCULATE_THRESHOLD_FAILURE,
  props<{ errors?: string; message: string }>()
);

export const GetExportLoan = createAction(GET_EXPORT_LOANS, props<{ LoanId: string; loanNumber: string }>());
export const GetExportLoanSuccess = createAction(GET_EXPORT_LOANS_SUCCESS, props<{ data: string }>());
export const GetExportLoanFailure = createAction(
  GET_EXPORT_LOANS_FAILURE,
  props<{ errors?: string[]; message: string }>()
);

export const GetAccountDetails = createAction(GET_ACCOUNT_DETAILS, props<{ loanId: string }>());

export const GetAccountDetailsSuccess = createAction(
  GET_ACCOUNT_DETAILS_SUCCESS,
  props<{ data: AccountSummaryDto[] }>()
);
export const GetAccountDetailsFailure = createAction(
  GET_ACCOUNT_DETAILS_FAILURE,
  props<{ errors: string[]; message: string }>()
);

export const GetIncomeDetails = createAction(GET_INCOME_DETAILS, props<{ loanId: string }>());

export const GetIncomeDetailsSuccess = createAction(GET_INCOME_DETAILS_SUCCESS, props<{ data: IncomeDetailsDto }>());
export const GetIncomeDetailsFailure = createAction(
  GET_INCOME_DETAILS_FAILURE,
  props<{ errors: string[]; message: string }>()
);
export const RESET_GET_INCOME_DETAILS = "RESET_GET_INCOME_DETAILS";
export const ResetGetIncomeDetails = createAction(RESET_GET_INCOME_DETAILS);

export const GetLoanHeaderDetailsById = createAction(GET_LOAN_HEADER_DETAILS_BY_ID, props<{ loanId: string }>());
export const GetLoanHeaderDetailsByIdSuccess = createAction(
  GET_LOAN_HEADER_DETAILS_BY_ID_SUCCESS,
  props<{ data: LoanHeaderDetails }>()
);
export const GetLoanHeaderDetailsByIdFailure = createAction(
  GET_LOAN_HEADER_DETAILS_BY_ID_FAILURE,
  props<{ errors: string[]; message: string }>()
);

export const GetTypeOfBusiness = createAction(GET_TYPE_OF_BUSINESS);
export const GetTypeOfBusinessSuccess = createAction(GET_TYPE_OF_BUSINESS_SUCCESS, props<{ data: TypeOfBusiness[] }>());
export const GetTypeOfBusinessFailure = createAction(
  GET_TYPE_OF_BUSINESS_FAILURE,
  props<{ errors: string[]; message: string }>()
);

export const GetEmployeeCount = createAction(GET_EMPLOYEE_COUNT);
export const GetEmployeeCountSuccess = createAction(GET_EMPLOYEE_COUNT_SUCCESS, props<{ data: EmployeeCount[] }>());
export const GetEmployeeCountFailure = createAction(
  GET_EMPLOYEE_COUNT_FAILURE,
  props<{ errors: string[]; message: string }>()
);

export const CalculateIncome = createAction(CALCULATE_INCOME, props<{ calculateIncomeBody: CalculateIncomeBody }>());
export const CalculateIncomeSuccess = createAction(CALCULATE_INCOME_SUCCESS, props<{ data: boolean }>());
export const CalculateIncomeFailure = createAction(
  CALCULATE_INCOME_FAILURE,
  props<{ errors: string; message: string }>()
);

export const GetCategoryByLoanId = createAction(GET_CATEGORY_BY_LOANID, props<{ loanId: string }>());

export const GetCategoryByLoanIdSuccess = createAction(
  GET_CATEGORY_BY_LOANID_SUCCESS,
  props<{ data: TransactionCategoriesDto[] }>()
);
export const GetCategoryByLoanIdFailure = createAction(
  GET_CATEGORY_BY_LOANID_FAILURE,
  props<{ errors: string[]; message: string }>()
);

export const UpdateAccount = createAction(UPDATE_ACCOUNT, props<{ accountId: string; isSelected: boolean }>());
export const UpdateAccountSuccess = createAction(UPDATE_ACCOUNT_SUCCESS, props<{ data: string }>());
export const UpdateAccountFailure = createAction(UPDATE_ACCOUNT_FAILURE, props<{ errors?: string; message: string }>());

export const UpdateAccountStatus = createAction(UPDATE_ACCOUNT_STATUS, props<{ data: AccountSummaryDto[] }>());
export const UpdateAccountStatusSuccess = createAction(UPDATE_ACCOUNT_STATUS_SUCCESS, props<{ data: string }>());
export const UpdateAccountStatusFailure = createAction(
  UPDATE_ACCOUNT_STATUS_FAILURE,
  props<{ errors?: string; message: string }>()
);

export const UpdateCategory = createAction(
  UPDATE_CATEGORY,
  props<{ loanId: string; isQualified: boolean; categoryCode: string }>()
);
export const UpdateCategorySuccess = createAction(UPDATE_CATEGORY_SUCCESS, props<{ data: string }>());
export const UpdateCategoryFailure = createAction(
  UPDATE_CATEGORY_FAILURE,
  props<{ errors?: string; message: string }>()
);

export type IncomeCalculationActions =
  | ActionType<typeof GetExportLoan>
  | ActionType<typeof GetExportLoanSuccess>
  | ActionType<typeof GetExportLoanFailure>
  | ActionType<typeof GetAccountDetails>
  | ActionType<typeof GetAccountDetailsSuccess>
  | ActionType<typeof GetAccountDetailsFailure>
  | ActionType<typeof GetIncomeDetails>
  | ActionType<typeof GetIncomeDetailsSuccess>
  | ActionType<typeof GetIncomeDetailsFailure>
  | ActionType<typeof ResetGetIncomeDetails>
  | ActionType<typeof GetLoanHeaderDetailsById>
  | ActionType<typeof GetLoanHeaderDetailsByIdSuccess>
  | ActionType<typeof GetLoanHeaderDetailsByIdFailure>
  | ActionType<typeof GetTypeOfBusiness>
  | ActionType<typeof GetTypeOfBusinessSuccess>
  | ActionType<typeof GetTypeOfBusinessFailure>
  | ActionType<typeof GetEmployeeCount>
  | ActionType<typeof GetEmployeeCountSuccess>
  | ActionType<typeof GetEmployeeCountFailure>
  | ActionType<typeof CalculateIncome>
  | ActionType<typeof CalculateIncomeSuccess>
  | ActionType<typeof CalculateIncomeFailure>
  | ActionType<typeof GetCategoryByLoanId>
  | ActionType<typeof GetCategoryByLoanIdSuccess>
  | ActionType<typeof GetCategoryByLoanIdFailure>
  | ActionType<typeof RecalculateThreshold>
  | ActionType<typeof RecalculateThresholdSuccess>
  | ActionType<typeof RecalculateThresholdFailure>
  | ActionType<typeof UpdateAccount>
  | ActionType<typeof UpdateAccountSuccess>
  | ActionType<typeof UpdateAccountFailure>
  | ActionType<typeof UpdateCategory>
  | ActionType<typeof UpdateCategorySuccess>
  | ActionType<typeof UpdateCategoryFailure>
  | ActionType<typeof UpdateAccountStatus>
  | ActionType<typeof UpdateAccountStatusSuccess>
  | ActionType<typeof UpdateAccountStatusFailure>;
